import React from "react"

import Layout from "../components/Layout"
import Head from "../components/Head"

const Thanks = () => {
  return (
    <Layout>
      <Head title="Thank you!"/>
        <div style={{ minHeight: "27.5vh" }}>
          <h1 className="title">Thanks for your message!</h1>
          <p className="thanks">
            I will get back to you as soon as I can <span aria-label="Smile" role="img">&#128512;</span>
          </p>
        </div>
    
    </Layout>
  )
}

export default Thanks
